import { SvgIcon } from '@mui/material'

const TopicCardAtom = () => {
	return (
		<SvgIcon viewBox="0 0 17 21" width={17} height={21}>
			<path fill="currentColor" d="M5.14563 4.21875H12.3115V6.42522H5.14563V4.21875Z" />
			<path fill="currentColor" d="M6.94203 19.2107L8.48768 20.1885V17.0547H4.75586V20.1885L6.3007 19.2107C6.49767 19.0869 6.74506 19.0869 6.94203 19.2107Z" />
			<path
				fill="currentColor"
				d="M15.751 14.2813H1.70593C1.29083 14.2813 0.953857 14.6308 0.953857 15.0595V17.4555C0.953857 17.885 1.29164 18.2337 1.70593 18.2337H3.52507V17.0537H2.93335C2.59313 17.0537 2.31803 16.7691 2.31803 16.417C2.31803 16.0659 2.59313 15.7804 2.93335 15.7804H10.3091C10.6493 15.7804 10.9244 16.065 10.9244 16.417C10.9244 16.7682 10.6493 17.0537 10.3091 17.0537H9.71735V18.2337H14.491C15.6004 18.2337 16.503 17.2997 16.503 16.1518V15.0594C16.503 14.6308 16.1661 14.2813 15.751 14.2813Z"
			/>
			<path
				fill="currentColor"
				d="M14.4913 0.783203H1.70618C1.29107 0.783203 0.954102 1.13272 0.954102 1.5614V13.1618C1.18607 13.0625 1.44001 13.0077 1.70618 13.0077H15.7513C16.0174 13.0077 16.2714 13.0633 16.5033 13.1618V2.86514C16.5033 1.71723 15.6006 0.783203 14.4913 0.783203ZM13.5422 7.06088C13.5422 7.41291 13.2671 7.69757 12.9269 7.69757H4.5304C4.19018 7.69757 3.91508 7.41291 3.91508 7.06088V3.58171C3.91508 3.22968 4.19018 2.94502 4.5304 2.94502H12.9269C13.2671 2.94502 13.5422 3.22968 13.5422 3.58171V7.06088Z"
			/>
		</SvgIcon>
	)
}

export default TopicCardAtom
